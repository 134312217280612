import { useDispatch } from 'react-redux';
import {
  setSimodontModelIdAndDownload,
  setSimodontModelFile,
  useSimodontModelState,
  stopSimodontModelLoading,
  clearSimodontModelData,
  setSimodontModelInfoAndPrepare,
  changeFileByName,
  changePadding,
  clearSimodontFormData,
  setSimodontInfoAndPrepareTargetInTooth,
  addTeethInfo,
  removeTeethInfo,
  clearTeethInfo,
  cancelSimodontModelConversion,
  clearSimodontModelDataAndSegmentData,
} from '../store';

const useSimodontModelFile = () => {
  const dispatch = useDispatch();
  const { file, teethInfo, teethType, info, formData, loading } = useSimodontModelState();

  const handleSetDownloadFile = (data) => {
    dispatch(setSimodontModelIdAndDownload(data));
  };

  const handlePrepareSimodontModel = (data) => {
    dispatch(setSimodontModelInfoAndPrepare(data));
  };

  const handleSetSimodontModel = (data) => {
    dispatch(setSimodontModelFile({ file: data }));
  };

  const handleStopSimodontModelLoading = (process) => {
    dispatch(stopSimodontModelLoading({ process }));
  };

  const handleChangeFileByName = (data) => {
    dispatch(changeFileByName(data));
  };

  const handleChangePadding = (data) => {
    dispatch(changePadding(data));
  };

  const handlePrepareTargetInToothModel = (data) => {
    dispatch(setSimodontInfoAndPrepareTargetInTooth(data));
  };

  const handleAddTeethInfo = (data) => {
    dispatch(addTeethInfo(data));
  };

  const handleRemoveTeethInfo = (data) => {
    dispatch(removeTeethInfo(data));
  };

  const handleCancelConversion = () => {
    dispatch(cancelSimodontModelConversion());
  };

  const handleClearTeethInfo = (data) => {
    dispatch(clearTeethInfo(data));
  };

  const handleClearFormData = () => {
    dispatch(clearSimodontFormData());
  };

  const handleClearAllData = () => {
    dispatch(clearSimodontModelData());
  };

  const handleClearFile = () => {
    dispatch(clearSimodontModelDataAndSegmentData());
  };

  const methods = {
    handleSetDownloadFile,
    handlePrepareSimodontModel,
    handleSetSimodontModel,
    handleStopSimodontModelLoading,
    handleChangeFileByName,
    handleChangePadding,
    handlePrepareTargetInToothModel,
    handleAddTeethInfo,
    handleRemoveTeethInfo,
    handleCancelConversion,
    handleClearTeethInfo,
    handleClearFormData,
    handleClearAllData,
    handleClearFile,
  };

  return { file, teethInfo, teethType, info, formData, loading, methods };
};

export default useSimodontModelFile;
