import nrrdSlice from './nrrdSlice';

export {
  uploadDicomFilesAction,
  saveNrrdToStore,
  uploadNrrdDataAction,
  cancelNrrdConversion,
  saveSegmentDataToStore,
} from './nrrdExtra';

export * from './nrrdActions';
export * from './nrrdHook';

export const {
  addUploadFiles,
  removeUploadFiles,
  setIdNrrd,
  setNrrdFile,
  setCurrentData,
  startNrrdLoading,
  stopNrrdLoading,
  clearSegmentInfo,
  clearDicomFiles,
  clearNrrdData,
} = nrrdSlice.actions;

export default nrrdSlice.reducer;
