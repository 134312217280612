export const FULL_JAW = 'FULL_JAW';
export const SINGLE_TOOTH = 'SINGLE_TOOTH';
export const TARGET_IN_TOOTH = 'TARGET_IN_TOOTH';
export const USER_MANAGEMENT = 'USER_MANAGEMENT';
export const SYSTEM_CONFIG = 'SYSTEM_CONFIG';

export const CUSTOMER_WORKFLOWS = [FULL_JAW, SINGLE_TOOTH, TARGET_IN_TOOTH];

export const WORKFLOW_LABELS = {
  [FULL_JAW]: 'CBCT Full jaw & partial jaw',
  [SINGLE_TOOTH]: 'CBCT Single tooth',
  [TARGET_IN_TOOTH]: 'Target in tooth',
  [USER_MANAGEMENT]: 'User management',
  [SYSTEM_CONFIG]: 'System configuration',
};

export const CONVERT_DIALOG_LABELS = {
  [FULL_JAW]: 'full jaw or partial jaw',
  [SINGLE_TOOTH]: 'single tooth',
};
