import {
  saveSimodontModelToStore,
  setIdSimodontModel,
  setSimodontModelInfo,
  prepareSimodontModelAction,
  uploadTargetInToothDataAction,
  setTeethInfo,
  clearSimodontModelFile,
} from '.';
import { SINGLE_TOOTH } from '../../constants';
import { clearSegmentInfo, saveSegmentDataToStore } from '../nrrd';

export const setSimodontModelIdAndDownload = (body) => (dispatch) => {
  dispatch(setIdSimodontModel(body));
  dispatch(saveSimodontModelToStore(body));
  dispatch(saveSegmentDataToStore());
};

export const setSimodontModelInfoAndPrepare = (info) => (dispatch) => {
  dispatch(setSimodontModelInfo(info));
  dispatch(clearSegmentInfo());
  if (localStorage.getItem('workflow') === SINGLE_TOOTH) {
    dispatch(setTeethInfo({ teethInfo: info.teethInfo }));
  }
  dispatch(prepareSimodontModelAction());
};

export const setSimodontInfoAndPrepareTargetInTooth = ({ name, description, teethInfo }) => (dispatch) => {
  dispatch(setSimodontModelInfo({
    name,
    description,
    toothId: teethInfo[0].toothPosition,
  }));
  dispatch(uploadTargetInToothDataAction());
};

export const clearSimodontModelDataAndSegmentData = () => (dispatch) => {
  dispatch(clearSimodontModelFile());
  dispatch(clearSegmentInfo());
};
