/* eslint-disable no-await-in-loop */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { NRRD, LOADING_CONSTANT, DRILLABLE, SINGLE_TOOTH } from '../../constants';
import { fileService } from '../../services';
import { sortFileArr } from '../../utils/helpers/func';
import { initialState } from './nrrdReducer';

// extra actions
export const uploadDicomFilesAction = createAsyncThunk('file/uploadDicomFilesAction', async (_, thunkAPI) => {
  const { dicomFiles } = thunkAPI.getState().nrrd;
  const sortArray = sortFileArr(dicomFiles, { name: 'filename', asc: true });
  const formData = new FormData();

  for (const file of sortArray) {
    formData.append('dicomFiles', file);
  }

  const { data } = await fileService.uploadFiles({ formData });

  return data;
});

export const saveNrrdToStore = createAsyncThunk('file/saveNrrdToStore', async (_, thunkAPI) => {
  const { file } = thunkAPI.getState().nrrd;
  const { data } = await fileService.downloadFile({ id: file.id, ext: NRRD });

  return { data };
});

export const uploadNrrdDataAction = createAsyncThunk('file/uploadNrrdDataAction', async (_, thunkAPI) => {
  const { file } = thunkAPI.getState().nrrd;
  const formData = new FormData();

  formData.append('file', file.data);

  const { data } = await fileService.uploadFileData({ formData });

  return data;
});

export const cancelNrrdConversion = createAsyncThunk('file/cancelNrrdConversion', async (_, thunkAPI) => {
  const { file, loading } = thunkAPI.getState().nrrd;

  if (loading[LOADING_CONSTANT.DICOM_CONVERSION]) {
    await fileService.cancelDicomConversion({ id: file.batchId });
  } else if (loading[LOADING_CONSTANT.NRRD_PREPARING]) {
    await fileService.cancelNrrdConversion({ id: file.batchId });
  }
});

export const saveSegmentDataToStore = createAsyncThunk('file/saveSegmentDataToStore', async (_, thunkAPI) => {
  const { file, teethInfo } = thunkAPI.getState().simodontModel;
  const segmentData = {};

  for (const tooth of teethInfo) {
    if (localStorage.getItem('workflow') === SINGLE_TOOTH || tooth.toothType === DRILLABLE) {
      const { data } = await fileService.downloadFile({ id: file.id, ext: 'sd', pos: tooth.toothPosition });

      segmentData[tooth.toothPosition] = data;
    }
  }

  return segmentData;
});


// extra reducer
export default {
  [uploadDicomFilesAction.pending]: (state) => {
    state.loading[LOADING_CONSTANT.DICOM_CONVERSION] = true;
    state.dowloadFile = null;
    state.currentData = { ...initialState.currentData };
    state.segmentData = { ...initialState.segmentData };
    state.file = { ...initialState.file };
  },
  [uploadDicomFilesAction.fulfilled]: (state, action) => {
    state.loading[LOADING_CONSTANT.DICOM_CONVERSION] = true;
    state.file.batchId = action.payload;

    notification.success({
      key: LOADING_CONSTANT.DICOM_CONVERSION,
      duration: 0,
      message: 'Dicom conversion',
      description: 'Files have been uploaded! Please wait until conversion is ready',
    });
  },
  [uploadDicomFilesAction.rejected]: (state) => {
    state.loading[LOADING_CONSTANT.DICOM_CONVERSION] = false;

    notification.error({
      key: LOADING_CONSTANT.DICOM_CONVERSION,
      duration: 3,
      message: 'Dicom conversion',
      description: 'Error when uploading files',
    });
  },

  [saveNrrdToStore.pending]: (state) => {
    state.loading[LOADING_CONSTANT.NRRD_DOWNLOAD] = true;
    if (state.loading[LOADING_CONSTANT.DICOM_CONVERSION] === true) {
      state.loading[LOADING_CONSTANT.DICOM_CONVERSION] = false;

      notification.success({
        key: LOADING_CONSTANT.DICOM_CONVERSION,
        duration: 3,
        message: 'Dicom conversion (100%)',
        description: 'Files have been converted! Result file is uploading',
      });
    } else if (state.loading[LOADING_CONSTANT.NRRD_PREPARING] === true) {
      state.loading[LOADING_CONSTANT.NRRD_PREPARING] = false;

      notification.success({
        key: LOADING_CONSTANT.NRRD_PREPARING,
        duration: 3,
        message: 'Nrrd preparing',
        description: 'File has been prepared! Result file is uploading',
      });
    }
  },
  [saveNrrdToStore.fulfilled]: (state, action) => {
    const { data } = action.payload;

    state.file = { ...state.file, data };
    state.loading[LOADING_CONSTANT.NRRD_DOWNLOAD] = false;
  },
  [saveNrrdToStore.rejected]: (state) => {
    state.loading[LOADING_CONSTANT.NRRD_DOWNLOAD] = false;

    notification.error({
      key: LOADING_CONSTANT.DICOM_CONVERSION,
      duration: 3,
      message: 'Nrrd downloading',
      description: 'Error when loading file',
    });
  },

  [uploadNrrdDataAction.pending]: (state) => {
    state.loading[LOADING_CONSTANT.NRRD_UPLOAD] = true;
  },
  [uploadNrrdDataAction.fulfilled]: (state, action) => {
    state.loading[LOADING_CONSTANT.NRRD_UPLOAD] = false;
    state.loading[LOADING_CONSTANT.NRRD_PREPARING] = true;
    state.file = { ...initialState, batchId: action.payload };

    notification.success({
      key: LOADING_CONSTANT.NRRD_PREPARING,
      duration: 0,
      message: 'Nrrd preparing',
      description: 'File has been uploaded',
    });
  },
  [uploadNrrdDataAction.rejected]: (state) => {
    state.loading[LOADING_CONSTANT.NRRD_UPLOAD] = false;
    state.file = { id: 0, data: null };

    notification.error({
      message: 'Nrrd uploading',
      description: 'Error when uploading file',
    });
  },

  [saveSegmentDataToStore.pending]: (state) => {
    state.loading[LOADING_CONSTANT.NRRD_DOWNLOAD] = true;

    notification.success({
      key: LOADING_CONSTANT.NRRD_PREPARING,
      duration: 3,
      message: 'Segment data nrrd preparing',
      description: 'File has been prepared! Result file is uploading',
    });
  },
  [saveSegmentDataToStore.fulfilled]: (state, action) => {
    const data = action.payload;

    state.segmentData = data;
    state.loading[LOADING_CONSTANT.NRRD_DOWNLOAD] = false;

    notification.success({
      key: LOADING_CONSTANT.NRRD_PREPARING,
      duration: 3,
      message: 'Segment data nrrd preparing',
      description: 'File has been prepared! Result file was uploaded',
    });
  },
  [saveSegmentDataToStore.rejected]: (state) => {
    state.loading[LOADING_CONSTANT.NRRD_DOWNLOAD] = false;

    notification.error({
      key: LOADING_CONSTANT.DICOM_CONVERSION,
      duration: 3,
      message: 'Segment data nrrd downloading',
      description: 'Error when loading file',
    });
  },
};
