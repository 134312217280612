const TOPIC_ERROR_NOTIFICATION = '/user/alarm';
const TOPIC_PROGRESS = '/user/progress';
const TOPIC_FILE_READY = '/user/file';
const TOPIC_QUEUE = '/user/queue';
const TOPIC_AUTH = '/user/auth';
const TOPIC_CLEANUP_STATUS = '/user/cleanup';

export const TOPICS = [
  TOPIC_ERROR_NOTIFICATION,
  TOPIC_PROGRESS,
  TOPIC_FILE_READY,
  TOPIC_CLEANUP_STATUS,
  TOPIC_AUTH,
  TOPIC_QUEUE,
];
